import { useToggleState } from "react-stately";
import {
  AriaSwitchProps,
  useFocusRing,
  useSwitch,
  VisuallyHidden
} from "react-aria";
import React, { FC } from "react";
import styled from "@emotion/styled";

const Label = styled.label`
  display: flex;
  align-items: center;
  opacity: 1;
  cursor: pointer;

  svg {
    margin-right: 0.5rem;
  }

  &[data-disabled="true"] {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export const Switch: FC<AriaSwitchProps> = (props) => {
  const state = useToggleState(props);
  const ref = React.useRef(null);
  const { inputProps } = useSwitch(props, state, ref);
  const { focusProps } = useFocusRing();

  return (
    <Label data-disabled={props.isDisabled}>
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>

      {!state.isSelected && (
        <svg
          width="38"
          height="24"
          viewBox="0 0 38 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="2" y="5" width="36" height="14" rx="7" fill="#746E74" />
          <g filter="url(#filter0_d_78_8949)">
            <circle cx="12" cy="12" r="10" fill="white" />
          </g>
          <defs>
            <filter
              id="filter0_d_78_8949"
              x="0"
              y="2"
              width="22"
              height="22"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-1" dy="1" />
              <feGaussianBlur stdDeviation="0.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_78_8949"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_78_8949"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )}

      {state.isSelected && (
        <svg
          width="38"
          height="24"
          viewBox="0 0 38 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="5"
            width="36"
            height="14"
            rx="7"
            fill="url(#paint0_linear_78_8952)"
          />
          <g filter="url(#filter0_d_78_8952)">
            <circle cx="26" cy="12" r="10" fill="white" />
          </g>
          <defs>
            <filter
              id="filter0_d_78_8952"
              x="16"
              y="2"
              width="22"
              height="22"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="0.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_78_8952"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_78_8952"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_78_8952"
              x1="1.41638e-08"
              y1="11.8571"
              x2="35.988"
              y2="11.2007"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#80AEFF" />
              <stop offset="0.526781" stop-color="#F7BDE6" />
              <stop offset="1" stop-color="#FFBD70" />
            </linearGradient>
          </defs>
        </svg>
      )}
      {props.children}
    </Label>
  );
};
